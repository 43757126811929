import Cookies from 'js-cookie';
import {URL_LOGIN} from "./url";
import {postService} from './RestService';

const loginCheck = () => {
    if(Cookies.get('isLoggedIn')===undefined || Cookies.get('isLoggedIn')===false) {
        return false
    }
    else {
        return Cookies.get('isLoggedIn') !== 'false';
    }
}

const handleLogin = async(body) => {
    const result = await postService(URL_LOGIN, body);
    if (result.resultInfo.success === true){
        Cookies.set('token', result.data.token);

        Cookies.set('isLoggedIn', true, { expires: 30 });
        console.log('Logged In')
    }else{
        console.log(result.resultInfo.message);
    }
    return result
}

const handleLogout = async () => {

    Cookies.remove('token');

    Cookies.set('isLoggedIn', false);

    console.log('Logged Out');
}

export {
    loginCheck,
    handleLogin,
    handleLogout
}
