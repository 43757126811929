
const Service = (file, maxWidth, maxHeight) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    // reader.onload = function () {
    //
    //   if (maxWidth && maxHeight) {
    //     const image = new Image();
    //     const dataUrl = reader.result
    //     image.src = dataUrl;  //encoded base64
    //
    //     const mimeType = dataUrl.substring(dataUrl.indexOf(":")+1, dataUrl.indexOf(";"));
    //
    //     image.onload = function() {
    //       const resizedResult = resizeImage(image, mimeType, maxWidth, maxHeight);
    //       resolve(resizedResult);
    //     }
    //   }
    //   else {
    //     resolve(reader.result);
    //   }
    // };

    reader.onload = function () {
      resolve(reader.result)
    }

    reader.onerror = reject;
  });
};

function resizeImage(image, mimeType, maxWidth, maxHeight) {
  const canvas = document.createElement('canvas');

  let width = image.width;
  let height = image.height;

  if (width > height) {
    if (width > maxWidth) {
      height = Math.round(height * maxWidth / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round(width * maxHeight / height);
      height = maxHeight;
    }
  }

  canvas.width = width;
  canvas.height = height;

  var ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, width, height);
  return canvas.toDataURL(mimeType);
}

export default Service;