import React, {useEffect, useState} from 'react'
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {Alert, Grid, Icon} from "@mui/material";
import {getService} from "../../utils/RestService";
import {URL_MATRIX_WEB, URL_PAGE_GET_ALL} from "../../utils/url";
import DataTable from "../../examples/Tables/DataTable";
import MDBadge from "../../components/MDBadge";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import {Link} from "react-router-dom";

function Pages(){
    //States
    const [language, setLanguage] = useState('en')
    const [pages, setPages] = useState([])

    //Constructors
    useEffect(() => {
        fetchPages().then(r => null)
    })

    //Functions
    const fetchPages = async() => {
        const result = await getService(URL_PAGE_GET_ALL)
        if(await result.resultInfo.success){
            let temp = []
            result.data.pages.map(p => {
                temp.push({
                    english: p.translations.find(o=>o.language_code==='en')? p.translations.find(o=>o.language_code==='en').title : '',
                    indonesian: p.translations.find(o=>o.language_code==='id')? p.translations.find(o=>o.language_code==='id').title : '',
                    status: (
                        <MDBox ml={-1}>
                            <MDBadge badgeContent={p.is_active? 'active':'inactive'} color={p.is_active? 'success':'secondary'} variant="gradient" size="md" />
                        </MDBox>
                    ),
                    action: (
                        <Grid container justifyContent={'space-between'} spacing={2}>
                            <Grid item>
                                <Link to={p.translations.find(o=>o.language_code==='en').slug}>
                                    <MDButton size={'small'} variant={'contained'} color={'secondary'}>
                                        <Icon>edit</Icon>
                                        &nbsp;Edit
                                    </MDButton>
                                </Link>
                            </Grid>
                            <Grid item>
                                <MDButton disabled size={'small'} variant={'text'} color={'error'}>
                                    <Icon>delete</Icon>
                                    &nbsp;Delete
                                </MDButton>
                            </Grid>
                        </Grid>
                    )
                })
            })
            setPages(temp)
        }
    }

    return(
        <DashboardLayout>
            <DashboardNavbar/>

            <Grid container>
                <Grid item xs={12}>
                    <DataTable
                        entriesPerPage={false}
                        isSorted={false}

                        table={{
                            columns:[
                                {Header: 'EN', accessor:'english'},
                                {Header: 'ID', accessor:'indonesian'},
                                {Header: 'Status', accessor:'status'},
                                {Header: 'Action', accessor:'action'}
                            ],
                            rows:pages
                        }}
                    />
                </Grid>
            </Grid>

        </DashboardLayout>
    )
}

export default Pages