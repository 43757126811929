/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useEffect, useState} from "react";

// react-router-dom components
import { Link, Navigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

//DEV
import {Alert} from "@mui/material";
import {handleLogin, loginCheck} from "../../utils/AuthService";
import Cookies from 'js-cookie';
import MDAlert from "../../components/MDAlert";


function Login() {
    //States
    const [rememberMe, setRememberMe] = useState(true);
    const [alert, setAlert] = useState({color:'warning', message:''})
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [redirect, setRedirect] = useState(false)

    //Constructors
    useEffect(()=>{
        if(loginCheck()) setRedirect(true)
    },[])

    //Functions
    const handleSetRememberMe = () => setRememberMe(!rememberMe);
    const initiateLogin = async() => {
        if(!email || !pass){
            setAlert({color:'warning', message:'Please fill all the field'})
        }else{
            setAlert({color:'info', message:'Please wait'})

            let body = {
                email: email,
                password: pass,
                jwt_expiration: 2592000
            }
            const result = await handleLogin(body);
            if(await result.resultInfo.success){
                setAlert({color:'success', message:'Successfully Logged In'})
                setTimeout(() => {
                    setRedirect(true)
                }, 2000);
            }else{
                setAlert({color:'error', message:result.resultInfo.errorMessage})
            }
        }
    }

    return (
        <BasicLayout image={bgImage}>

            {/*Redirect if Logged In*/}
            {redirect?
                <Navigate to={'/articles'}/> : null
            }

            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"
                >
                    <MDTypography variant="h4" fontWeight="medium" color="white">
                        Log In
                    </MDTypography>
                    {/*<Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>*/}
                    {/*    <Grid item xs={2}>*/}
                    {/*        <MDTypography component={MuiLink} href="#" variant="body1" color="white">*/}
                    {/*            <FacebookIcon color="inherit" />*/}
                    {/*        </MDTypography>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={2}>*/}
                    {/*        <MDTypography component={MuiLink} href="#" variant="body1" color="white">*/}
                    {/*            <GitHubIcon color="inherit" />*/}
                    {/*        </MDTypography>*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={2}>*/}
                    {/*        <MDTypography component={MuiLink} href="#" variant="body1" color="white">*/}
                    {/*            <GoogleIcon color="inherit" />*/}
                    {/*        </MDTypography>*/}
                    {/*    </Grid>*/}
                    {/*</Grid>*/}
                </MDBox>
                <MDBox pt={4} pb={3} px={3}>
                    <MDBox component="form" role="form">
                        <MDBox mb={2}>
                            <MDInput type="email" label="Email" fullWidth onChange={(e)=>setEmail(e.target.value)} value={email}/>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="password" label="Password" fullWidth onChange={(e)=>setPass(e.target.value)} value={pass}/>
                        </MDBox>
                        <MDBox display="flex" alignItems="center" ml={-1}>
                            <Switch checked={rememberMe} onChange={handleSetRememberMe} disabled/>
                            <MDTypography
                                variant="button"
                                fontWeight="regular"
                                color="text"
                                onClick={handleSetRememberMe}
                                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                            >
                                &nbsp;&nbsp;Remember me for 30 days
                            </MDTypography>
                        </MDBox>

                        {alert.message?
                            <MDAlert color={alert.color}>{alert.message}</MDAlert>
                            :null
                        }

                        <MDBox mt={4} mb={1}>
                            <MDButton variant="gradient" color="info" fullWidth onClick={()=>initiateLogin()} disabled={alert.severity==='info'}>
                                Log In
                            </MDButton>
                        </MDBox>
                        {/*<MDBox mt={3} mb={1} textAlign="center">*/}
                        {/*    <MDTypography variant="button" color="text">*/}
                        {/*        Don&apos;t have an account?{" "}*/}
                        {/*        <MDTypography*/}
                        {/*            component={Link}*/}
                        {/*            to="/authentication/sign-up"*/}
                        {/*            variant="button"*/}
                        {/*            color="info"*/}
                        {/*            fontWeight="medium"*/}
                        {/*            textGradient*/}
                        {/*        >*/}
                        {/*            Sign up*/}
                        {/*        </MDTypography>*/}
                        {/*    </MDTypography>*/}
                        {/*</MDBox>*/}
                    </MDBox>
                </MDBox>
            </Card>
        </BasicLayout>
    );
}

export default Login;
