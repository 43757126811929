import React, {useEffect, useState} from 'react'
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {FormControl, Grid, Icon, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup} from "@mui/material";
import MDButton from "../../components/MDButton";
import DataTable from "../../examples/Tables/DataTable";
import Switch from "@mui/material/Switch";
import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import {getService} from "../../utils/RestService";
import {URL_ART_PAGINATE, URL_CAT_PAGINATE, URL_MATRIX_WEB} from "../../utils/url";
import {parseDate} from "../../utils/Utils";
import Loader from "../../components/loader/loader";
import {Link} from "react-router-dom";

function Articles(){
    //States
    const [categoryFilter, setCategoryFilter] = useState('All')
    const [categories, setCategories] = useState([])
    const [articles, setArticles] = useState([])
    const [language, setLanguage] = useState('id')

    //Constructors
    useEffect(()=>{
        fetchCategories().then(r => null)
    },[])
    useEffect(()=>{
        fetchArticles().then(r => null)
    },[categoryFilter])

    //Functions
    const fetchArticles = async() => {
        //API URL
        let API_URL = URL_ART_PAGINATE(1,1000)
        if(categoryFilter!=='All') API_URL+='&cat='+categoryFilter

        const result = await getService(API_URL)
        if(await result.resultInfo.success){
            let temp = []
            result.data.articles.map(a => {
                temp.push({
                    title: a.translations.find(o => o.language_code === language).title,
                    date: parseDate(a.article_date, true),
                    status: (
                        <MDBox ml={-1}>
                            <MDBadge badgeContent={a.is_active? 'active':'inactive'} color={a.is_active? 'success':'secondary'} variant="gradient" size="md" />
                        </MDBox>
                    ),
                    action: (
                        <Grid container justifyContent={'space-between'} spacing={2}>
                            <Grid item>
                                <MDButton component={'a'}
                                          target={'_blank'}
                                          rel={'noreferrer'}
                                          href={URL_MATRIX_WEB + '/highlights/article/' + language + '/' + a.translations.find(o => o.language_code === language).slug}
                                          size={'small'} variant={'contained'} color={'info'}
                                          disabled={!a.is_active}
                                >
                                    <Icon>open_in_new</Icon>
                                    &nbsp;View
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <Link to={a._id}>
                                    <MDButton size={'small'} variant={'contained'} color={'secondary'}>
                                        <Icon>edit</Icon>
                                        &nbsp;Edit
                                    </MDButton>
                                </Link>
                            </Grid>
                            <Grid item>
                                <MDButton disabled size={'small'} variant={'text'} color={'error'}>
                                    <Icon>delete</Icon>
                                    &nbsp;Delete
                                </MDButton>
                            </Grid>
                        </Grid>
                    )
                })
            })
            setArticles(temp)
        }
    }
    const fetchCategories = async() => {
        const result = await getService(URL_CAT_PAGINATE)
        if(await result.resultInfo.success){
            let temp = []
            result.data.article_category.map(c => {
                temp.push({
                    id: c._id,
                    english: c.translations.find(o => o.language_code === 'en').category_name
                })
            })
            setCategories(temp)
        }
    }


    return(
        <DashboardLayout>
            <DashboardNavbar/>
            <Grid container>
                <Grid container item xs={12} mb={3} justifyContent={'space-between'}>
                    <Grid container item xs={4} spacing={1}>
                        <Grid item>
                            <ToggleButtonGroup
                                color={'info'}
                                value={language}
                                exclusive
                                disabled
                            >
                                <ToggleButton value={'id'}>ID</ToggleButton>
                                <ToggleButton value={'en'}>EN</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        {categories.length>0?
                            <Grid item xs={10}>
                                <FormControl fullWidth sx={{height:'100%'}}>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        label={'Category'}
                                        sx={{height:'100%'}}
                                        value={categoryFilter}
                                        onChange={e=>setCategoryFilter(e.target.value)}
                                    >
                                        <MenuItem value={'All'}>All</MenuItem>
                                        {categories.map(c => (
                                            <MenuItem value={c.id}>{c.english}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            :null
                        }
                    </Grid>
                    <Grid item xs={3}>
                        <Link to={'new'}>
                            <MDButton color={'success'} fullWidth>
                                <Icon>post_add</Icon>
                                &nbsp;New Article
                            </MDButton>
                        </Link>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    {articles.length>0?
                        <DataTable
                            entriesPerPage={false}
                            isSorted={false}
                            canSearch

                            table={{
                                columns:[
                                    {Header: 'Title', accessor:'title'},
                                    {Header: 'Published Date', accessor:'date'},
                                    {Header: 'Status', accessor:'status'},
                                    {Header: 'Action', accessor:'action'}
                                ],
                                rows:articles
                            }}
                        />
                        :
                        <Loader/>
                    }
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default Articles