import React, {useEffect, useState} from 'react'
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {useParams} from "react-router-dom";
import {getService, postService} from "../../utils/RestService";
import {URL_MATRIX_WEB, URL_PROD_GET_ID, URL_PROD_UPDATE} from "../../utils/url";
import MDAlert from "../../components/MDAlert";
import {
    Divider,
    FormControl,
    Grid,
    Icon, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";

function EditBroadbandProduct(){
    //States
    const productId = useParams().id
    const language = 'en';
    const [resetData, setResetData] = useState(null)
    const [productData, setProductData] = useState({
        featured_order: 0,
        is_active: false,
        product_name: ''
    })
    const [productContent, setProductContent] = useState({
        header: '',
        price: '',
        recurrence: '',
        perks: [],
        action_text: '',
        action_link: ''
    })
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({color:'warning', message:''})

    //Constructors
    useEffect(()=>{
        fetchProduct().then(r => null)
    },[])

    //Functions
    const fetchProduct = async() => {
        const result = await getService(URL_PROD_GET_ID(productId))
        if(await result.resultInfo.success){
            let data = result.data.product;
            setResetData(data)
            setProductData({
                featured_order: data.featured_order,
                is_active: data.is_active,
                product_name: data.product_name
            })
            setProductContent(data.translations.find(o=>o.language_code===language).content)
        }
    }

    const handleResetChanges = () => {
        let data = resetData
        setProductData({
            featured_order: data.featured_order,
            is_active: data.is_active,
            product_name: data.product_name
        })
        setProductContent(data.translations.find(o=>o.language_code===language).content)

    }

    const handleChange = (event, data) => {
        switch (data){
            case 'data':
                setProductData({ ...productData, [event.target.name]: event.target.value });
                break;
            case 'content':
                setProductContent({ ...productContent, [event.target.name]: event.target.value });
                break;
        }
    };

    const handleChangeDescription = (event, index) => {
        let temp = productContent.perks.slice()
        temp[index] = event.target.value
        setProductContent({
            ...productContent,
            perks: temp
        });
    }
    const handleAddDescription = () => {
        let temp = productContent.perks.slice();
        temp.push('New Description')
        setProductContent({
            ...productContent,
            perks: temp
        });
    }
    const handleRemoveDescription = (index) => {
        let temp = productContent.perks.slice();
        temp.splice(index, 1)
        setProductContent({
            ...productContent,
            perks: temp
        });
    }

    const handleSaveChanges = async() => {
        setLoading(true)
        setAlert({color:'info', message:'Updating Page. Please Wait'})

        let body={
            ...productData,
            _id: productId,
            is_featured: true,
            translations: [
                {
                    language_code: language,
                    content: productContent
                }
            ]
        }

        const result = await postService(URL_PROD_UPDATE, body)
        if(await result.resultInfo.success){
            setLoading(false)
            setAlert({color:'success', message:'Successfully updated product'})

            let data = result.data.product;
            setResetData(data)
        }

        setTimeout(() => {
            setAlert({color:'success', message:''})
        }, 3000);
    }

    return(
        <DashboardLayout>
            <DashboardNavbar/>

            {alert.message?
                <MDAlert color={alert.color}>{alert.message}</MDAlert>
                :null
            }

            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Card>
                        <MDBox p={3}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <MDTypography variant="h6" gutterBottom>
                                        Product Name
                                    </MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDButton onClick={()=>handleResetChanges()} disabled={loading} color={'dark'}>
                                        <Icon>restart_alt</Icon>
                                        &nbsp;Reset All Changes
                                    </MDButton>
                                </Grid>
                            </Grid>

                            <MDInput value={productData.product_name} onChange={e=>handleChange(e, 'data')} name={'product_name'} label={'Product Name'} fullWidth margin={'normal'}/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Product Content
                            </MDTypography>

                            <MDInput value={productContent.header} onChange={e=>handleChange(e,'content')} name={'header'} label={'Header'} fullWidth margin={'normal'}/>

                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <MDInput value={productContent.price} onChange={e=>handleChange(e,'content')} name={'price'} label={'Price'} fullWidth margin={'normal'}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <MDInput value={productContent.recurrence} onChange={e=>handleChange(e,'content')} name={'recurrence'} label={'Recurrence'} fullWidth margin={'normal'}/>
                                </Grid>
                            </Grid>

                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Product Description
                            </MDTypography>

                            {productContent.perks.length>0?
                                productContent.perks.map((j, index) => (
                                    <MDInput
                                        key={index} value={j} onChange={e=>handleChangeDescription(e, index)}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position={'end'}>
                                                    <MDButton
                                                        onClick={()=>handleRemoveDescription(index)}
                                                        circular color={'error'} variant={'text'} iconOnly>
                                                        <Icon>clear</Icon>
                                                    </MDButton>
                                                </InputAdornment>
                                        }}
                                        label={'Description'} fullWidth margin={'dense'}/>
                                ))
                                :
                                <MDTypography variant="subtitle2" gutterBottom>
                                    No Description Yet
                                </MDTypography>
                            }

                            <MDBox mt={2}>
                                <MDButton onClick={()=>handleAddDescription()} color={'light'}>
                                    <Icon>add</Icon>
                                    &nbsp;Add Description
                                </MDButton>
                            </MDBox>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Product Action Button
                            </MDTypography>

                            <MDInput value={productContent.action_text} onChange={e=>handleChange(e,'content')} name={'action_text'} label={'Text'} fullWidth margin={'normal'}/>
                            <MDInput value={productContent.action_link} onChange={e=>handleChange(e,'content')} name={'action_link'} label={'Link'} fullWidth margin={'normal'}/>

                        </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container justifyContent={'space-between'} flexDirection={'column'} spacing={2} p={2}>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Product Status
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'success'}
                                    value={productData.is_active}
                                    exclusive
                                >
                                    <ToggleButton value={false}>Inactive</ToggleButton>
                                    <ToggleButton value={true}>Active</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Product Type
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'primary'}
                                    value={true}
                                    exclusive
                                >
                                    <ToggleButton value={true}>Broadband</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Product Order
                                </MDTypography>
                                <MDInput value={productData.featured_order} onChange={e=>handleChange(e,'data')} name={'featured_order'} label={'Order'} type={'number'} min={'0'} fullWidth margin={'normal'}/>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Switch Language
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'info'}
                                    value={language}
                                    exclusive
                                >
                                    <ToggleButton value={'en'}>EN</ToggleButton>
                                    <ToggleButton value={'id'}>ID</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Actions
                                </MDTypography>
                                <MDButton onClick={()=>handleSaveChanges()} disabled={loading} variant={'gradient'} color={'success'} fullWidth>
                                    <Icon>save</Icon>
                                    &nbsp;Save Changes
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton disabled variant={'gradient'} color={'error'} fullWidth>
                                    <Icon>delete</Icon>
                                    &nbsp;Delete Product
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

        </DashboardLayout>
    )
}

export default EditBroadbandProduct