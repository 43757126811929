/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import {Login} from "./pages/Auth";
import {EditAboutUs, EditBroadband, EditCareer, EditHighlights, EditHome, Pages} from "./pages/Pages";
import {ArticleCategories, Articles, EditArticle} from "./pages/Articles";
import {EditBroadbandProduct, EditEnterpriseProduct, Products} from "./pages/Products";
import {Careers, EditJobPost} from "./pages/Careers";
import EditContactUs from "./pages/Pages/EditPage/EditContactUs";
import CreateArticle from "./pages/Articles/CreateArticle";

const routes = [
  {
    type: "collapse",
    name: "Articles",
    key: "articles",
    icon: <Icon fontSize="small">article</Icon>,
    route: "/articles",
    component: <Articles />,
  },
  {
    name: "Create Article",
    key: "editArticle",
    route: "/articles/new",
    component: <CreateArticle />,
  },
  {
    name: "Edit Article",
    key: "editArticle",
    route: "/articles/:id",
    component: <EditArticle />,
  },
  {
    type: "collapse",
    name: "Article Categories",
    key: "articleCategories",
    icon: <Icon fontSize="small">format_list_bulleted</Icon>,
    route: "/articleCategories",
    component: <ArticleCategories />,
  },
  {
    type: "collapse",
    name: "Pages",
    key: "pages",
    icon: <Icon fontSize="small">description</Icon>,
    route: "/pages",
    component: <Pages />,
  },
  {
    type: "collapse",
    name: "Products",
    key: "products",
    icon: <Icon fontSize="small">cloud</Icon>,
    route: "/products",
    component: <Products />,
  },
  {
    name: "Edit Broadband Product",
    key: "editBroadbandProduct",
    icon: <Icon fontSize="small">cloud</Icon>,
    route: "/products/broadband/:id",
    component: <EditBroadbandProduct />,
  },
  {
    name: "Edit Enterprise Product",
    key: "editEnterpriseProduct",
    icon: <Icon fontSize="small">cloud</Icon>,
    route: "products/enterprise/:id",
    component: <EditEnterpriseProduct />,
  },
  {
    type: "collapse",
    name: "Careers",
    key: "careers",
    icon: <Icon fontSize="small">work</Icon>,
    route: "/careers",
    component: <Careers />,
  },
  {
    name: "Edit Career",
    key: "editCareer",
    icon: <Icon fontSize="small">work</Icon>,
    route: "/careers/:id",
    component: <EditJobPost />,
  },
  //  EDIT PAGES
  {
    name: "Edit About Us Page",
    key: "editAboutUs",
    route: "/pages/about-us",
    component: <EditAboutUs />,
  },
  {
    name: "Edit Home Page",
    key: "editHome",
    route: "/pages/home",
    component: <EditHome />,
  },
  {
    name: "Edit Broadband Page",
    key: "editBroadband",
    route: "/pages/broadband",
    component: <EditBroadband />,
  },
  {
    name: "Edit Career Page",
    key: "editCareer",
    route: "/pages/career",
    component: <EditCareer />,
  },
  {
    name: "Edit Contact Us Page",
    key: "editContactUs",
    route: "/pages/contact-us",
    component: <EditContactUs />,
  },
  {
    name: "Edit Highlights Page",
    key: "editHighlights",
    route: "/pages/highlights",
    component: <EditHighlights />,
  },
  //  TEMPLATE
  {
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    name: "Tables",
    key: "tables",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/tables",
    component: <Tables />,
  },
  {
    name: "Billing",
    key: "billing",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/billing",
    component: <Billing />,
  },
  {
    name: "RTL",
    key: "rtl",
    icon: <Icon fontSize="small">format_textdirection_r_to_l</Icon>,
    route: "/rtl",
    component: <RTL />,
  },
  {
    name: "Notifications",
    key: "notifications",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/notifications",
    component: <Notifications />,
  },
  {
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
  },
  {
    name: "Login",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/login",
    component: <Login />,
  },
  {
    name: "Sign Up",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
