import React, {useEffect, useState} from 'react'
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {Alert, Grid, Icon} from "@mui/material";
import DataTable from "../../examples/Tables/DataTable";
import {URL_CAR_GET_ALL} from "../../utils/url";
import {getService} from "../../utils/RestService";
import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import MDButton from "../../components/MDButton";
import {Link} from "react-router-dom";

function Careers(){
    //States
    const lang = 'en'
    const [careers, setCareers] = useState([])

    //Constructors
    useEffect(()=>{
        fetchCareers().then(r => null)
    },[])

    //Functions
    const fetchCareers = async() => {
        let URL_API = URL_CAR_GET_ALL(1,100)
        const result = await getService(URL_API)
        if(await result.resultInfo.success){
            let temp = []

            result.data.job_posts.map(j => {
                temp.push({
                    title: j.translations.find(o=>o.language_code===lang).job_title,
                    status: (
                        <MDBox ml={-1}>
                            <MDBadge badgeContent={j.is_active? 'active':'inactive'} color={j.is_active? 'success':'secondary'} variant="gradient" size="md" />
                        </MDBox>
                    ),
                    action: (
                        <Grid container justifyContent={'space-between'} spacing={2}>
                            <Grid item>
                                <Link to={j._id}>
                                    <MDButton size={'small'} variant={'contained'} color={'secondary'}>
                                        <Icon>edit</Icon>
                                        &nbsp;Edit
                                    </MDButton>
                                </Link>
                            </Grid>
                            <Grid item>
                                <MDButton disabled size={'small'} variant={'text'} color={'error'}>
                                    <Icon>delete</Icon>
                                    &nbsp;Delete
                                </MDButton>
                            </Grid>
                        </Grid>
                    )
                })
            })

            setCareers(temp)
        }
    }

    return(
        <DashboardLayout>
            <DashboardNavbar/>
            <Grid container>
                <Grid container item xs={12} mb={3} justifyContent={'flex-end'}>
                    <Grid item xs={3}>
                        <MDButton disabled color={'success'} fullWidth>
                            <Icon>add</Icon>
                            &nbsp;Create New Job Post
                        </MDButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <DataTable
                        entriesPerPage={false}
                        isSorted={false}

                        table={{
                            columns:[
                                {Header: 'Job Title', accessor:'title'},
                                {Header: 'Status', accessor:'status'},
                                {Header: 'Action', accessor:'action'}
                            ],
                            rows:careers
                        }}
                    />
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default Careers