import React, {useEffect, useState} from 'react'
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {Grid, Icon} from "@mui/material";
import DataTable from "../../examples/Tables/DataTable";
import MDBox from "../../components/MDBox";
import MDBadge from "../../components/MDBadge";
import MDTypography from "../../components/MDTypography";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import {getService} from "../../utils/RestService";
import {URL_CAT_PAGINATE} from "../../utils/url";
import Loader from "../../components/loader/loader";
import MDButton from "../../components/MDButton";

function ArticleCategories(){
    //States
    const [categories, setCategories] = useState([])

    //Constructors
    useEffect(()=>{
        fetchCategories().then(r => null)
    },[])

    //Functions
    const fetchCategories = async() => {
        const result = await getService(URL_CAT_PAGINATE)
        if(await result.resultInfo.success){
            let temp = []
            result.data.article_category.map(c => {
                temp.push({
                    english: c.translations.find(o => o.language_code === 'en').category_name,
                    indonesian: c.translations.find(o => o.language_code === 'id').category_name,
                    status: (
                        <MDBox ml={-1}>
                            <MDBadge badgeContent={c.is_active? 'active':'inactive'} color={c.is_active? 'success':'secondary'} variant="gradient" size="md" />
                        </MDBox>
                    ),
                    action: (
                        <Grid container justifyContent={'space-between'} spacing={2}>
                            <Grid item>
                                <MDButton disabled size={'small'} variant={'contained'} color={'secondary'}>
                                    <Icon>edit</Icon>
                                    &nbsp;Edit
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton disabled size={'small'} variant={'text'} color={'error'}>
                                    <Icon>delete</Icon>
                                    &nbsp;Delete
                                </MDButton>
                            </Grid>
                        </Grid>

                    )
                })
            })
            setCategories(temp)
        }
    }

    return(
        <DashboardLayout>
            <DashboardNavbar/>
            <Grid container>
                <Grid container item xs={12} mb={3} justifyContent={'flex-end'}>
                    <Grid item xs={3}>
                        <MDButton disabled color={'success'} fullWidth>
                            <Icon>playlist_add</Icon>
                            &nbsp;Create New Category
                        </MDButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    {categories.length>0?
                        <DataTable
                            entriesPerPage={false}
                            table={{
                                columns:[
                                    {Header: 'EN', accessor:'english'},
                                    {Header: 'ID', accessor:'indonesian'},
                                    {Header: 'Status', accessor:'status'},
                                    {Header: 'Action', accessor:'action'}
                                ],
                                rows:categories
                            }}
                        />
                        :
                        <Loader/>
                    }
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default ArticleCategories