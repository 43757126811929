import React, {useEffect, useState} from 'react'
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDAlert from "../../components/MDAlert";
import {
    Divider,
    FormControl,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    Select,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import TextEditor from "../../components/TextEditor/TextEditor";
import {URL_ART_NEW, URL_CAT_PAGINATE, URL_MATRIX_WEB} from "../../utils/url";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import {useNavigate} from "react-router-dom";
import {EditorState} from "draft-js";
import {getService, postService} from "../../utils/RestService";
import {convertHtmlToString, convertStringToHtml} from "../../utils/Utils";

function CreateArticle (){
    //States
    const navigate = useNavigate()
    const language = 'id';
    const [categories, setCategories] = useState([])
    const [articleData, setArticleData] = useState({
        is_active: false,
        meta_tags: '',
        category_id: '',
        article_date: '',
        main_img:''
    })
    const [articleTitle, setArticleTitle] = useState('')
    const [articleContent, setArticleContent] = useState({
        meta_description: '',
        text: '',
        aboutMatrix: '',
        mediaInquiry: '',
        contactForm: ''
    })
    const [articleEditor, setArticleEditor] = useState(EditorState.createEmpty())
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({color:'warning', message:''})

    //Constructors
    useEffect(()=>{
       fetchCategories().then(r => null)
    },[])

    //Functions
    const fetchCategories = async() => {
        const result = await getService(URL_CAT_PAGINATE)
        if(await result.resultInfo.success){
            let temp = []
            result.data.article_category.map(c => {
                temp.push({
                    id: c._id,
                    english: c.translations.find(o => o.language_code === 'en').category_name
                })
            })
            setCategories(temp)
        }
    }

    const handleResetChanges = () => {

        setArticleData({
            is_active: false,
            meta_tags: '',
            category_id: '',
            article_date: ''
        })

        setArticleTitle('')

        setArticleContent({
            meta_description: '',
            text: '',
            aboutMatrix: '',
            mediaInquiry: '',
            contactForm: ''
        })

        setArticleEditor(EditorState.createEmpty())
    }

    const handleChange = (event, data) => {
        switch (data){
            case 'data':
                setArticleData({ ...articleData, [event.target.name]: event.target.value });
                break;
            case 'title':
                setArticleTitle(event.target.value)
                break;
            case 'content':
                setArticleContent({ ...articleContent, [event.target.name]: event.target.value });
                break;
        }
    };

    const handleCreateArticle = async() => {
        setLoading(true)
        setAlert({color:'info', message:'Creating content. Please Wait'})

        let content = {
            ...articleContent,
            text: convertStringToHtml(articleEditor.getCurrentContent())
        }

        let body = {
            category_id: articleData.category_id,
            article_date: articleData.article_date+'T00:00:00Z',
            is_active: articleData.is_active,
            meta_tags: articleData.meta_tags.split(','),
            translations:[
                {
                    language_code: language,
                    title: articleTitle,
                    content: content
                }
            ],
            main_img: ''
        }

        const result = await postService(URL_ART_NEW, body)
        if(await result.resultInfo.success){
            setLoading(false)
            setAlert({color:'success', message:'Successfully created page'})
            setTimeout(() => {
                navigate('/articles')
            }, 3000);
        }else{
            setLoading(false)
            setAlert({color:'error', message:result?.resultInfo?.resultDescription})
        }


    }


    return(
        <DashboardLayout>
            <DashboardNavbar/>

            {alert.message?
                <MDAlert color={alert.color}>{alert.message}</MDAlert>
                :null
            }

            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Card>
                        <MDBox p={3}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <MDTypography variant="h6" gutterBottom>
                                        Article Content
                                    </MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDButton onClick={()=>handleResetChanges()} disabled={loading} color={'dark'}>
                                        <Icon>restart_alt</Icon>
                                        &nbsp;Reset All Changes
                                    </MDButton>
                                </Grid>
                            </Grid>

                            <MDInput value={articleTitle} onChange={e=>handleChange(e, 'title')} label={'Title'} fullWidth margin={'normal'} helperText={'Title will also be an H1 element on webpage'}/>
                            {/*<MDInput value={articleContent.text} onChange={e=>handleChange(e,'content')} name={'text'} label={'Text'} multiline fullWidth margin={'normal'}/>*/}
                            <TextEditor value={articleEditor} onChange={setArticleEditor}/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Meta
                            </MDTypography>

                            <MDInput value={articleContent.meta_description} onChange={e=>handleChange(e, 'content')} name={'meta_description'} label={'Description'} fullWidth margin={'normal'} multiline/>
                            <MDInput value={articleData.meta_tags} onChange={e=>handleChange(e,'data')} name={'meta_tags'} label={'Keywords'} fullWidth margin={'normal'} helperText={'Example: keyword1,keyword2,etc.; no spaces between'}/>

                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Article Footer
                            </MDTypography>

                            <MDInput value={articleContent.aboutMatrix} onChange={e=>handleChange(e,'content')} name={'aboutMatrix'} label={'About Matrix'} fullWidth margin={'normal'} multiline/>
                            <MDInput value={articleContent.mediaInquiry} onChange={e=>handleChange(e,'content')} name={'mediaInquiry'} label={'Media Inquiry'} fullWidth margin={'normal'} multiline/>
                        </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container justifyContent={'space-between'} flexDirection={'column'} spacing={2} p={2}>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Article Status
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={articleData.is_active?'success':'error'}
                                    value={articleData.is_active}
                                    exclusive
                                >
                                    <ToggleButton value={false}>Inactive</ToggleButton>
                                    <ToggleButton value={true}>Active</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Switch Language
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'info'}
                                    value={language}
                                    exclusive
                                >
                                    <ToggleButton value={'en'}>EN</ToggleButton>
                                    <ToggleButton value={'id'}>ID</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Publish Date
                                </MDTypography>
                                <MDInput value={articleData.article_date} onChange={e=>handleChange(e,'data')} name={'article_date'} type={"date"} fullWidth/>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Category
                                </MDTypography>
                                {categories.length>0?
                                    <FormControl fullWidth sx={{height:'5vh'}}>
                                        <InputLabel>Category</InputLabel>
                                        <Select
                                            label={'Category'}
                                            sx={{height:'100%'}}
                                            value={articleData.category_id}
                                            onChange={e=>handleChange(e,'data')}
                                            name={'category_id'}
                                        >
                                            {categories.map(c => (
                                                <MenuItem value={c.id}>{c.english}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    :null
                                }
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Actions
                                </MDTypography>
                                <MDButton onClick={()=>handleCreateArticle()} disabled={loading} variant={'gradient'} color={'success'} fullWidth>
                                    <Icon>save</Icon>
                                    &nbsp;Save Changes
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

        </DashboardLayout>
    )
}

export default CreateArticle