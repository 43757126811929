import React, {useEffect, useState} from 'react'
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import {Divider, Grid, Icon, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import {useLocation} from "react-router-dom";
import {getService, postService} from "../../../utils/RestService";
import {URL_PAGE_GET_ID, URL_PAGE_GET_SLUG, URL_PAGE_UPDATE} from "../../../utils/url";
import MDAlert from "../../../components/MDAlert";

function EditAboutUs(){
    //States
    const [language, setLanguage] = useState('en')
    const [pageStatus, setPageStatus] = useState(true)
    const slug = useLocation().pathname.substring(useLocation().pathname.lastIndexOf('/')+1)

    const [pageId, setPageId] = useState(null)
    const [resetData, setResetData] = useState(null)
    const [pageData, setPageData] = useState({
        meta_title: '',
        meta_description: '',
        meta_keywords: '',
        vision_title: '',
        vision_text: '',
        mission_title: '',
        mission_text: '',
        intro_title: '',
        intro_text: '',
        values_subtitle: '',
        values_title: '',
        values_list: []
    })
    const [newValuesList, setNewValuesList] = useState(null)
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({color:'warning', message:''})

    //Constructors
    useEffect(()=>{
        fetchPageBySlug().then(r => null)
    },[])

    //Functions
    const fetchPageBySlug = async() => {
        const result = await getService(URL_PAGE_GET_SLUG(language, slug))
        if(await result.resultInfo.success){
            setPageId(result.data.pages._id)
            setPageStatus(result.data.pages.is_active)
            let data = result.data.pages.translations.find(o=>o.language_code===language).content

            setPageData(data)
            setResetData(data)

            setNewValuesList({
                value1: data.values_list[0],
                value2: data.values_list[1],
                value3: data.values_list[2],
                value4: data.values_list[3],
                value5: data.values_list[4],
            })
        }
    }

    const handleChange = (event) => {
        setPageData({ ...pageData, [event.target.name]: event.target.value });
    };

    const handleChangeValues = (event) => {
        setNewValuesList({ ...newValuesList, [event.target.name]: event.target.value });
    }

    const handleResetChanges = () => {
        setPageData(resetData)
        setNewValuesList({
            value1: resetData.values_list[0],
            value2: resetData.values_list[1],
            value3: resetData.values_list[2],
            value4: resetData.values_list[3],
            value5: resetData.values_list[4],
        })
    }

    const handleSaveChanges = async() => {
        setLoading(true)
        setAlert({color:'info', message:'Updating Page. Please Wait'})

        let tempData = {
            ...pageData,
            values_list: Object.values(newValuesList)
        }

        let body = {
            _id: pageId,
            is_active: pageStatus,
            translations: [
                {
                    language_code: language,
                    title: 'About Us',
                    content: tempData
                }
            ]
        }

        const result = await postService(URL_PAGE_UPDATE, body)
        if(await result.resultInfo.success){
            setResetData(tempData)
            setPageData(tempData)
            setLoading(false)
            setAlert({color:'success', message:'Successfully updated page'})
        }

        setTimeout(() => {
            setAlert({color:'success', message:''})
        }, 3000);

    };

    return(
        <DashboardLayout>
            <DashboardNavbar/>

            {alert.message?
                <MDAlert color={alert.color}>{alert.message}</MDAlert>
                :null
            }

            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Card>
                        <MDBox p={3}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <MDTypography variant="h6" gutterBottom>
                                        Meta
                                    </MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDButton onClick={()=>handleResetChanges()} disabled={loading} color={'dark'}>
                                        <Icon>restart_alt</Icon>
                                        &nbsp;Reset All Changes
                                    </MDButton>
                                </Grid>
                            </Grid>

                            <MDInput value={pageData.meta_title} onChange={handleChange} name={'meta_title'} label={'Title'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.meta_description} onChange={handleChange} name={'meta_description'} label={'Description'} fullWidth margin={'normal'} multiline/>
                            <MDInput value={pageData.meta_keywords} onChange={handleChange} name={'meta_keywords'} label={'Keywords'}  fullWidth margin={'normal'} helperText={'Example: keyword1, keyword2, etc.'}/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Vision
                            </MDTypography>

                            <MDInput value={pageData.vision_title} onChange={handleChange} name={'vision_title'} label={'Title'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.vision_text} onChange={handleChange} name={'vision_text'} label={'Text'} fullWidth margin={'normal'} multiline/>
                        </MDBox>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Mission
                            </MDTypography>

                            <MDInput value={pageData.mission_title} onChange={handleChange} name={'mission_title'} label={'Title'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.mission_text} onChange={handleChange} name={'mission_text'} label={'Text'} fullWidth margin={'normal'} multiline/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Company Intro
                            </MDTypography>

                            <MDInput value={pageData.intro_title} onChange={handleChange} name={'intro_title'} label={'Title'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.intro_text} onChange={handleChange} name={'intro_text'} label={'Text'} fullWidth margin={'normal'} multiline/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Company Values
                            </MDTypography>

                            <MDInput value={pageData.values_subtitle} onChange={handleChange} name={'values_subtitle'} label={'Subtitle'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.values_title} onChange={handleChange} name={'values_title'} label={'Title'} fullWidth margin={'normal'}/>
                        </MDBox>
                        {newValuesList?
                            <MDBox p={3}>
                                <MDTypography variant="h6" gutterBottom>
                                    Company Values List
                                </MDTypography>
                                <MDInput value={newValuesList.value1} onChange={handleChangeValues} name={'value1'} label={'Value'} fullWidth margin={'dense'} size={'small'}/>
                                <MDInput value={newValuesList.value2} onChange={handleChangeValues} name={'value2'} label={'Value'} fullWidth margin={'dense'} size={'small'}/>
                                <MDInput value={newValuesList.value3} onChange={handleChangeValues} name={'value3'} label={'Value'} fullWidth margin={'dense'} size={'small'}/>
                                <MDInput value={newValuesList.value4} onChange={handleChangeValues} name={'value4'} label={'Value'} fullWidth margin={'dense'} size={'small'}/>
                                <MDInput value={newValuesList.value5} onChange={handleChangeValues} name={'value5'} label={'Value'} fullWidth margin={'dense'} size={'small'}/>
                            </MDBox>
                            :null
                        }
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container justifyContent={'space-between'} flexDirection={'column'} spacing={2} p={2}>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Page Status
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'success'}
                                    value={pageStatus}
                                    exclusive
                                >
                                    <ToggleButton value={false}>Inactive</ToggleButton>
                                    <ToggleButton value={true}>Active</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Switch Language
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'info'}
                                    value={language}
                                    exclusive
                                >
                                    <ToggleButton value={'en'}>EN</ToggleButton>
                                    <ToggleButton value={'id'}>ID</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Actions
                                </MDTypography>
                                <MDButton onClick={()=>handleSaveChanges()} disabled={loading} variant={'gradient'} color={'success'} fullWidth>
                                    <Icon>save</Icon>
                                    &nbsp;Save Changes
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton disabled variant={'gradient'} color={'error'} fullWidth>
                                    <Icon>delete</Icon>
                                    &nbsp;Delete Page
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

        </DashboardLayout>
    )
}

export default EditAboutUs