//Matrix Site
let matrix_web = process.env.REACT_APP_NAP_WEBSITE_DEV; //Staging
if (process.env.REACT_APP_ENVIRONMENT === "production") {
    matrix_web = process.env.REACT_APP_NAP_WEBSITE_PROD; //Production
}
export const URL_MATRIX_WEB = matrix_web

//API URL
let URL_MAIN = process.env.REACT_APP_API_DEV; //Staging
if (process.env.REACT_APP_ENVIRONMENT === "production") {
    URL_MAIN = process.env.REACT_APP_API_PROD; //Production
}

export const URL_ENDPOINT = URL_MAIN + '/';
const URL_API = URL_MAIN + '/api';
const URL_ADMIN = URL_API + '/admin'
const URL_PUBLIC = URL_API + '/public'

//Auth
const URL_AUTH = URL_API + '/auth'
export const URL_LOGIN = URL_AUTH + '/login'

//Article Category
const URL_CAT = URL_ADMIN + '/article-categories'
export const URL_CAT_PAGINATE = URL_CAT + '/all'
export const URL_CAT_GET_BY_ID = (id) => URL_CAT + `/${id}`
export const URL_CAT_NEW = URL_CAT + '/create'
export const URL_CAT_UPDATE = URL_CAT + '/update'
export const URL_CAT_DELETE = URL_CAT + '/delete'

//Article
const URL_ART = URL_ADMIN + '/articles'
export const URL_ART_PAGINATE = (page, size) => URL_ART + `/paginate?page=${page}&size=${size}`
export const URL_ART_BY_ID = (id) => URL_ART + `/${id}`
export const URL_ART_NEW = URL_ART + '/create'
export const URL_ART_UPDATE = URL_ART + '/update'
export const URL_ART_DELETE = URL_ART + '/delete'
const URL_ART_MED = URL_ART + '/media'
export const URL_ART_MED_ADD = URL_ART_MED + '/create'
export const URL_ART_MED_DEL = URL_ART_MED + '/delete'

//Pages
const URL_PAGE = URL_ADMIN + '/pages'
export const URL_PAGE_GET_ALL = URL_PAGE + '/all'
export const URL_PAGE_GET_ID = (id) => URL_PAGE + `/${id}`
export const URL_PAGE_NEW = URL_PAGE + '/create'
export const URL_PAGE_UPDATE = URL_PAGE + '/update'
export const URL_PAGE_DELETE = URL_PAGE + '/delete'
const URL_PAGE_MEDIA = URL_PAGE + '/media'
const URL_PAGE_MEDIA_ADD = URL_PAGE_MEDIA + '/create'
const URL_PAGE_MEDIA_DEL = URL_PAGE_MEDIA + '/delete'

const URL_PAGE_PUBLIC = URL_PUBLIC + '/pages'
export const URL_PAGE_GET_SLUG = (lang, slug) => URL_PAGE_PUBLIC + `/${lang}/${slug}`

//Products
const URL_PROD = URL_ADMIN + '/products'
export const URL_PROD_GET_ALL = (page, size) => URL_PROD + `/paginate?page=${page}&size=${size}`
export const URL_PROD_GET_ID = (id) => URL_PROD + `/${id}`
export const URL_PROD_NEW = URL_PROD + '/create'
export const URL_PROD_UPDATE = URL_PROD + '/update'
export const URL_PROD_DELETE = URL_PROD + '/delete'
const URL_PROD_MEDIA = URL_PROD + '/media'
const URL_PROD_MEDIA_ADD = URL_PROD_MEDIA + '/create'
const URL_PROD_MEDIA_DEL = URL_PROD_MEDIA + '/delete'

//Careers
const URL_CAR = URL_ADMIN + '/careers'
export const URL_CAR_GET_ALL = (page, size) => URL_CAR + `/paginate?page=${page}&size=${size}`
export const URL_CAR_GET_ID = (id) => URL_CAR + `/${id}`
export const URL_CAR_NEW = URL_CAR + '/create'
export const URL_CAR_UPDATE = URL_CAR + '/update'
export const URL_CAR_DELETE = URL_CAR + '/delete'
const URL_CAR_MEDIA = URL_CAR + '/media'
const URL_CAR_MEDIA_ADD = URL_CAR_MEDIA + '/create'
const URL_CAR_MEDIA_DEL = URL_CAR_MEDIA + '/delete'

