import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';
import {ContentState, convertToRaw} from "draft-js";

export const parseDate = (s, noTime = false) => {
    let d = new Date(s);

    let time = d.getHours() + ':' + (d.getMinutes()<10?'0':'') + d.getMinutes();
    let date = d.getDate() +'/'+ (d.getMonth()+1) +'/'+ d.getFullYear();

    // return d.toLocaleString()
    if(noTime) return date
    else return date + ', ' + time;
}
export const convertStringToHtml = (s) => {
    return draftToHtml(convertToRaw(s))
}
export const convertHtmlToString = (h) => {
    const contentBlock = htmlToDraft(h)
    if(contentBlock){
        return ContentState.createFromBlockArray(contentBlock.contentBlocks)
    }
}