import React, {useEffect, useState} from 'react'
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import {useLocation} from "react-router-dom";
import {getService, postService} from "../../../utils/RestService";
import {URL_PAGE_GET_SLUG, URL_PAGE_UPDATE} from "../../../utils/url";
import MDAlert from "../../../components/MDAlert";
import {Divider, Grid, Icon, Paper, ToggleButton, ToggleButtonGroup} from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";

function EditBroadband(){
    //States
    const [language, setLanguage] = useState('en')
    const [pageStatus, setPageStatus] = useState(true)
    const slug = useLocation().pathname.substring(useLocation().pathname.lastIndexOf('/')+1)

    const [pageId, setPageId] = useState(null)
    const [resetData, setResetData] = useState(null)
    const [pageData, setPageData] = useState({
        meta_title: '',
        meta_description: '',
        meta_keywords: '',
        header_text: '',
        header_text_highlight: '',
        header_subtext: '',
        call_to_action_text: '',
        call_to_action_button_text: '',
        call_to_action_link: '',
        sidebutton_coverage_link: '',
        sidebutton_coverage_text: '',
        sidebutton_whatsapp_link: '',
        sidebutton_whatsapp_text: '',
        location_title: '',
        location_text: '',
        location_list: [],
        tnc_title: '',
        tnc_caption: '',
        tnc_list: []
    })
    const [newLocations, setNewLocations] = useState('')
    const [newTNC, setNewTNC] = useState('')
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({color:'warning', message:''})

    //Constructors
    useEffect(()=>{
        fetchPageBySlug().then(r => null)
    },[])

    //Functions
    const fetchPageBySlug = async() => {
        const result = await getService(URL_PAGE_GET_SLUG(language, slug))
        if(await result.resultInfo.success){
            setPageId(result.data.pages._id)
            setPageStatus(result.data.pages.is_active)
            let data = result.data.pages.translations.find(o=>o.language_code===language).content

            setPageData(data)
            setResetData(data)

            setNewLocations(data.location_list.toString())
            setNewTNC(data.tnc_list.toString())
        }
    }

    const handleChange = (event) => {
        setPageData({ ...pageData, [event.target.name]: event.target.value });
    };

    const handleResetChanges = () => {
        setPageData(resetData)
        setNewLocations(resetData.location_list.toString())
        setNewTNC(resetData.tnc_list.toString())
    }

    const handleSaveChanges = async() => {
        setLoading(true)
        setAlert({color:'info', message:'Updating Page. Please Wait'})

        let tempData = { ...pageData,
            location_list: newLocations.split(','),
            tnc_list: newTNC.split(',')
        }

        let body = {
            _id: pageId,
            is_active: pageStatus,
            translations: [
                {
                    language_code: language,
                    title: 'Broadband',
                    content: tempData
                }
            ]
        }

        const result = await postService(URL_PAGE_UPDATE, body)
        if(await result.resultInfo.success){
            setResetData(tempData)
            setPageData(tempData)
            setLoading(false)
            setAlert({color:'success', message:'Successfully updated page'})
        }

        setTimeout(() => {
            setAlert({color:'success', message:''})
        }, 3000);

    };

    return(
        <DashboardLayout>
            <DashboardNavbar/>

            {alert.message?
                <MDAlert color={alert.color}>{alert.message}</MDAlert>
                :null
            }

            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Card>
                        <MDBox p={3}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <MDTypography variant="h6" gutterBottom>
                                        Meta
                                    </MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDButton onClick={()=>handleResetChanges()} disabled={loading} color={'dark'}>
                                        <Icon>restart_alt</Icon>
                                        &nbsp;Reset All Changes
                                    </MDButton>
                                </Grid>
                            </Grid>

                            <MDInput value={pageData.meta_title} onChange={handleChange} name={'meta_title'} label={'Title'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.meta_description} onChange={handleChange} name={'meta_description'} label={'Description'} fullWidth margin={'normal'} multiline/>
                            <MDInput value={pageData.meta_keywords} onChange={handleChange} name={'meta_keywords'} label={'Keywords'} fullWidth margin={'normal'} helperText={'Example: keyword1, keyword2, etc.'}/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Header
                            </MDTypography>

                            <MDInput value={pageData.header_text} onChange={handleChange} name={'header_text'} label={'Text'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.header_text_highlight} onChange={handleChange} name={'header_text_highlight'} label={'Text Highlight'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.header_subtext} onChange={handleChange} name={'header_subtext'} label={'Subtext'} fullWidth margin={'normal'} multiline/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Call To Action
                            </MDTypography>

                            <MDInput value={pageData.call_to_action_text} onChange={handleChange} name={'call_to_action_text'} label={'Text'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.call_to_action_button_text} onChange={handleChange} name={'call_to_action_button_text'} label={'Button Text'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.call_to_action_link} onChange={handleChange} name={'call_to_action_link'} label={'Link'} fullWidth margin={'normal'}/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Coverage - Side Button
                            </MDTypography>

                            <MDInput value={pageData.sidebutton_coverage_text} onChange={handleChange} name={'sidebutton_coverage_text'} label={'Text'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.sidebutton_coverage_link} onChange={handleChange} name={'sidebutton_coverage_link'} label={'Link'} fullWidth margin={'normal'}/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                WhatsApp - Side Button
                            </MDTypography>

                            <MDInput value={pageData.sidebutton_whatsapp_text} onChange={handleChange} name={'sidebutton_whatsapp_text'} label={'Text'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.sidebutton_whatsapp_link} onChange={handleChange} name={'sidebutton_whatsapp_link'} label={'Link'} fullWidth margin={'normal'}/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Locations
                            </MDTypography>

                            <MDInput value={pageData.location_title} onChange={handleChange} name={'location_title'} label={'Title'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.location_text} onChange={handleChange} name={'location_text'} label={'Text'} fullWidth margin={'normal'}/>
                            <MDInput value={newLocations} onChange={e=>setNewLocations(e.target.value)} label={'Location'} fullWidth margin={'normal'} helperText={'Seperate entry with commas'}/>

                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Terms & Conditions
                            </MDTypography>

                            <MDInput value={pageData.tnc_title} onChange={handleChange} name={'tnc_title'} label={'Title'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.tnc_caption} onChange={handleChange} name={'tnc_caption'} label={'Caption'} fullWidth margin={'normal'}/>
                            <MDInput value={newTNC} onChange={e=>setNewTNC(e.target.value)} label={'Terms & Conditions'} fullWidth margin={'normal'} helperText={'Seperate entry with commas'} multiline/>

                        </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container justifyContent={'space-between'} flexDirection={'column'} spacing={2} p={2}>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Page Status
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'success'}
                                    value={pageStatus}
                                    exclusive
                                >
                                    <ToggleButton value={false}>Inactive</ToggleButton>
                                    <ToggleButton value={true}>Active</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Switch Language
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'info'}
                                    value={language}
                                    exclusive
                                >
                                    <ToggleButton value={'en'}>EN</ToggleButton>
                                    <ToggleButton value={'id'}>ID</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Actions
                                </MDTypography>
                                <MDButton onClick={()=>handleSaveChanges()} disabled={loading} variant={'gradient'} color={'success'} fullWidth>
                                    <Icon>save</Icon>
                                    &nbsp;Save Changes
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton disabled variant={'gradient'} color={'error'} fullWidth>
                                    <Icon>delete</Icon>
                                    &nbsp;Delete Page
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

        </DashboardLayout>
    )
}

export default EditBroadband