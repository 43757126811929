import React, {useEffect, useState} from 'react'
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import {useLocation} from "react-router-dom";
import {getService, postService} from "../../../utils/RestService";
import {URL_PAGE_GET_SLUG, URL_PAGE_UPDATE} from "../../../utils/url";
import MDAlert from "../../../components/MDAlert";
import {Divider, Grid, Icon, ToggleButton, ToggleButtonGroup} from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import MDInput from "../../../components/MDInput";

function EditCareer(){
    //States
    const [language, setLanguage] = useState('en')
    const [pageStatus, setPageStatus] = useState(true)
    const slug = useLocation().pathname.substring(useLocation().pathname.lastIndexOf('/')+1)

    const [pageId, setPageId] = useState(null)
    const [resetData, setResetData] = useState(null)
    const [pageData, setPageData] = useState({
        meta_title: '',
        meta_description: '',
        meta_keywords: '',
        header_title: '',
        instruction_text: '',
        instruction_mail: ''
    })
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({color:'warning', message:''})

    //Constructors
    useEffect(()=>{
        fetchPageBySlug().then(r => null)
    },[])

    //Functions
    const fetchPageBySlug = async() => {
        const result = await getService(URL_PAGE_GET_SLUG(language, slug))
        if(await result.resultInfo.success){
            setPageId(result.data.pages._id)
            setPageStatus(result.data.pages.is_active)
            let data = result.data.pages.translations.find(o=>o.language_code===language).content

            setPageData(data)
            setResetData(data)
        }
    }

    const handleChange = (event) => {
        setPageData({ ...pageData, [event.target.name]: event.target.value });
    };

    const handleResetChanges = () => {
        setPageData(resetData)
    }

    const handleSaveChanges = async() => {
        setLoading(true)
        setAlert({color:'info', message:'Updating Page. Please Wait'})

        let body = {
            _id: pageId,
            is_active: pageStatus,
            translations: [
                {
                    language_code: language,
                    title: 'Career',
                    content: pageData
                }
            ]
        }

        const result = await postService(URL_PAGE_UPDATE, body)
        if(await result.resultInfo.success){
            setResetData(pageData)
            setLoading(false)
            setAlert({color:'success', message:'Successfully updated page'})
        }

        setTimeout(() => {
            setAlert({color:'success', message:''})
        }, 3000);

    };

    return(
        <DashboardLayout>
            <DashboardNavbar/>

            {alert.message?
                <MDAlert color={alert.color}>{alert.message}</MDAlert>
                :null
            }

            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Card>
                        <MDBox p={3}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <MDTypography variant="h6" gutterBottom>
                                        Meta
                                    </MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDButton onClick={()=>handleResetChanges()} disabled={pageData===resetData || loading} color={'dark'}>
                                        <Icon>restart_alt</Icon>
                                        &nbsp;Reset All Changes
                                    </MDButton>
                                </Grid>
                            </Grid>

                            <MDInput value={pageData.meta_title} onChange={handleChange} name={'meta_title'} label={'Title'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.meta_description} onChange={handleChange} name={'meta_description'} label={'Description'} fullWidth margin={'normal'} multiline/>
                            <MDInput value={pageData.meta_keywords} onChange={handleChange} name={'meta_keywords'} label={'Keywords'} fullWidth margin={'normal'} helperText={'Example: keyword1, keyword2, etc.'}/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Header
                            </MDTypography>

                            <MDInput value={pageData.header_title} onChange={handleChange} name={'header_title'} label={'Title'} fullWidth margin={'normal'}/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Application Instruction
                            </MDTypography>

                            <MDInput value={pageData.instruction_text} onChange={handleChange} name={'instruction_text'} label={'Text'} fullWidth margin={'normal'}/>
                            <MDInput value={pageData.instruction_mail} onChange={handleChange} name={'instruction_mail'} label={'Mail'} fullWidth margin={'normal'}/>
                        </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container justifyContent={'space-between'} flexDirection={'column'} spacing={2} p={2}>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Page Status
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'success'}
                                    value={pageStatus}
                                    exclusive
                                >
                                    <ToggleButton value={false}>Inactive</ToggleButton>
                                    <ToggleButton value={true}>Active</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Switch Language
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'info'}
                                    value={language}
                                    exclusive
                                >
                                    <ToggleButton value={'en'}>EN</ToggleButton>
                                    <ToggleButton value={'id'}>ID</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Actions
                                </MDTypography>
                                <MDButton onClick={()=>handleSaveChanges()} disabled={resetData===pageData || loading} variant={'gradient'} color={'success'} fullWidth>
                                    <Icon>save</Icon>
                                    &nbsp;Save Changes
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton disabled variant={'gradient'} color={'error'} fullWidth>
                                    <Icon>delete</Icon>
                                    &nbsp;Delete Page
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

        </DashboardLayout>
    )
}

export default EditCareer