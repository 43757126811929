import React, {useEffect, useState} from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import './TextEditor.css'
import {EditorState, convertToRaw} from "draft-js";
import draftToHtml from 'draftjs-to-html';

const TextEditor = ({value, onChange}) =>{
    const [editorState, setEditorState] = useState(EditorState.createEmpty())


    return(
        <div>
            <Editor
                wrapperClassName={'TextEditorWrapper'}
                editorClassName={'TextEditorContent'}
                toolbarClassName={'TextEditorToolbar'}
                placeholder={<div>Write here...</div>}
                toolbarOnFocus
                toolbar={toolbar}
                editorState={value}
                onEditorStateChange={(e)=>onChange(e)}
            />
            {/*<button onClick={()=>console.log(typeof draftToHtml(convertToRaw(editorState.getCurrentContent())))}>test</button>*/}
        </div>
    )


}

export default TextEditor

const toolbar = {
    options: [
        'history',
        'inline',
        'blockType',
        // 'fontSize',
        // 'fontFamily',
        'list',
        'textAlign',
        // 'colorPicker',
        'link',
        // 'embedded',
        // 'emoji',
        // 'image',
        // 'remove',
    ]
}