import React, {useEffect, useState} from 'react'
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {useParams} from "react-router-dom";
import {getService, postService} from "../../utils/RestService";
import {
    URL_ART_BY_ID,
    URL_ART_MED_ADD, URL_ART_MED_DEL,
    URL_ART_UPDATE,
    URL_CAT_PAGINATE,
    URL_ENDPOINT,
    URL_MATRIX_WEB
} from "../../utils/url";
import MDAlert from "../../components/MDAlert";
import {
    CardActionArea,
    CardActions,
    Divider,
    FormControl,
    Grid,
    Icon,
    InputLabel,
    MenuItem,
    Select,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";
import {EditorState} from "draft-js";
import TextEditor from "../../components/TextEditor/TextEditor";
import {convertHtmlToString, convertStringToHtml} from "../../utils/Utils";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import imageBase64EncoderService from "../../utils/ImageBase64EncoderService";

function EditArticle(){
    //States
    const articleId = useParams().id
    const language = 'id';
    const [categories, setCategories] = useState([])
    const [resetData, setResetData] = useState(null)
    const [articleData, setArticleData] = useState({
        is_active: true,
        meta_tags: '',
        category_id: '',
        article_date: '',
        main_img:''
    })
    const [articleTitle, setArticleTitle] = useState('')
    const [articleContent, setArticleContent] = useState({
        meta_description: '',
        text: '',
        aboutMatrix: '',
        mediaInquiry: '',
        contactForm: ''
    })
    const [articleEditor, setArticleEditor] = useState(EditorState.createEmpty())
    const [articleMedia, setArticleMedia] = useState([])

    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({color:'warning', message:''})

    //Constructors
    useEffect(()=>{
       fetchCategories().then(r => null)
       fetchArticleById().then(r => null)
    },[])

    //Functions
    const fetchCategories = async() => {
        const result = await getService(URL_CAT_PAGINATE)
        if(await result.resultInfo.success){
            let temp = []
            result.data.article_category.map(c => {
                temp.push({
                    id: c._id,
                    english: c.translations.find(o => o.language_code === 'en').category_name
                })
            })
            setCategories(temp)
        }
    }
    const fetchArticleById = async() => {
        const result = await getService(URL_ART_BY_ID(articleId))
        if(await result.resultInfo.success){
            let data = result.data.articles
            let translation = result.data.articles.translations.find(o=>o.language_code===language)

            setResetData(data)

            setArticleData({
                is_active: data.is_active,
                meta_tags: data.meta_tags.toString(),
                category_id: data.category_id,
                article_date: data.article_date.substring(0, data.article_date.indexOf('T')),
                main_img: data.main_img
            })

            setArticleTitle(translation.title)

            setArticleContent({
                meta_description: translation.content.meta_description,
                text: translation.content.text,
                aboutMatrix: translation.content.aboutMatrix,
                mediaInquiry: translation.content.mediaInquiry,
                contactForm: translation.content.contactForm
            })

            setArticleEditor(EditorState.createWithContent(convertHtmlToString(translation.content.text)))

            setArticleMedia(data.media)

        }
    }

    const handleUploadMedia = async(event) => {
        setLoading(true)
        setAlert({color:'warning', message:'Uploading media'})

        if(event.target.files[0]){
            //Initiate
            let file = event.target.files[0];
            let replace = 'data:'+ file.type +';base64,';
            let b64 = ''
            let fileType = file.type.substring(file.type.lastIndexOf("/") + 1)

            //Base64
            b64 = await imageBase64EncoderService(file, 1000, 1000);
            b64 = b64.replace(replace, '');


            let body = {
                article_id: articleId,
                metadata: {
                    alt: 'article_media'
                },
                media_ext: fileType,
                media_data: b64
            }

            const result = await postService(URL_ART_MED_ADD, body)
            if(await result.resultInfo.success){
                setLoading(false)
                setAlert({color:'success', message:'Successfully uploaded media'})

                await fetchArticleById().then(()=>{
                    setAlert({color:'success', message:''})
                })
            }

        }
    }

    const handleDeleteMedia = async(media_id) => {
        setLoading(true)
        setAlert({color:'warning', message:'Removing media'})

        let body = {
            article_id: articleId,
            media_id: media_id
        }

        const result = await postService(URL_ART_MED_DEL, body)
        if(await result.resultInfo.success){
            setLoading(false)
            setAlert({color:'success', message:'Successfully removed media'})

            await fetchArticleById().then(()=>{
                setAlert({color:'success', message:''})
            })
        }
    }

    const handleResetChanges = () => {
        let data = resetData
        let translation = data.translations.find(o=>o.language_code===language)

        setArticleData({
            is_active: data.is_active,
            meta_tags: data.meta_tags.toString(),
            category_id: data.category_id,
            article_date: data.article_date.substring(0, data.article_date.indexOf('T'))
        })

        setArticleTitle(translation.title)

        setArticleContent({
            meta_description: translation.content.meta_description,
            text: translation.content.text,
            aboutMatrix: translation.content.aboutMatrix,
            mediaInquiry: translation.content.mediaInquiry,
            contactForm: translation.content.contactForm
        })

        setArticleEditor(EditorState.createWithContent(convertHtmlToString(translation.content.text)))
    }

    const handleChange = (event, data) => {
        switch (data){
            case 'data':
                setArticleData({ ...articleData, [event.target.name]: event.target.value });
                break;
            case 'title':
                setArticleTitle(event.target.value)
                break;
            case 'content':
                setArticleContent({ ...articleContent, [event.target.name]: event.target.value });
                break;
        }
    };

    const handleSaveChanges = async() => {
        setLoading(true)
        setAlert({color:'info', message:'Updating Page. Please Wait'})

        let content = {
            ...articleContent,
            text: convertStringToHtml(articleEditor.getCurrentContent())
        }

        let body = {
            _id: articleId,
            category_id: articleData.category_id,
            article_date: articleData.article_date+'T00:00:00Z',
            is_active: articleData.is_active,
            meta_tags: articleData.meta_tags.split(','),
            main_img: articleData.main_img,
            translations:[
                {
                    language_code: language,
                    title: articleTitle,
                    content: content
                }
            ]
        }

        const result = await postService(URL_ART_UPDATE, body)
        if(await result.resultInfo.success){
            setLoading(false)
            setAlert({color:'success', message:'Successfully updated page'})

            setResetData(result.data.articles).then(r=>handleResetChanges())
        }

        setTimeout(() => {
            setAlert({color:'success', message:''})
        }, 3000);

    }

    return(
        <DashboardLayout>
            <DashboardNavbar/>

            {alert.message?
                <MDAlert color={alert.color}>{alert.message}</MDAlert>
                :null
            }

            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Card>
                        <MDBox p={3}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <MDTypography variant="h6" gutterBottom>
                                        Article Content
                                    </MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDButton onClick={()=>handleResetChanges()} disabled={loading} color={'dark'}>
                                        <Icon>restart_alt</Icon>
                                        &nbsp;Reset All Changes
                                    </MDButton>
                                </Grid>
                            </Grid>

                            <MDInput value={articleTitle} onChange={e=>handleChange(e, 'title')} label={'Title'} fullWidth margin={'normal'} helperText={'Title will also be an H1 element on webpage'}/>
                            {/*<MDInput value={articleContent.text} onChange={e=>handleChange(e,'content')} name={'text'} label={'Text'} multiline fullWidth margin={'normal'}/>*/}
                            <TextEditor value={articleEditor} onChange={setArticleEditor}/>
                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Meta
                            </MDTypography>

                            <MDInput value={articleContent.meta_description} onChange={e=>handleChange(e, 'content')} name={'meta_description'} label={'Description'} fullWidth margin={'normal'} multiline/>
                            <MDInput value={articleData.meta_tags} onChange={e=>handleChange(e,'data')} name={'meta_tags'} label={'Keywords'} fullWidth margin={'normal'} helperText={'Example: keyword1,keyword2,etc.; no spaces between'}/>

                        </MDBox>
                        <Divider/>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Article Footer
                            </MDTypography>

                            <MDInput value={articleContent.aboutMatrix} onChange={e=>handleChange(e,'content')} name={'aboutMatrix'} label={'About Matrix'} fullWidth margin={'normal'} multiline/>
                            <MDInput value={articleContent.mediaInquiry} onChange={e=>handleChange(e,'content')} name={'mediaInquiry'} label={'Media Inquiry'} fullWidth margin={'normal'} multiline/>
                        </MDBox>
                    </Card>

                    {/*Article Media*/}
                    <Card style={{marginTop:'3vh'}}>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Article Media
                            </MDTypography>

                            <MDBox bgColor={'light'} borderRadius={'md'} p={1} mb={2}>
                                {articleMedia.length>0?
                                    <>
                                        <Grid container spacing={2}>
                                            {articleMedia.map(m => (
                                                <Grid item xs={3} key={m._id}>
                                                    <Card>
                                                        <CardMedia
                                                            component={'img'}
                                                            alt={'article_media'}
                                                            image={URL_ENDPOINT + m.path}
                                                            height={'150'}
                                                        />
                                                        <MDBox p={1}>
                                                            <MDButton
                                                                variant={'contained'} color={'info'} fullWidth size={'small'}
                                                                disabled={m.path===articleData.main_img}
                                                                name={'main_img'}
                                                                onClick={e=>handleChange({
                                                                    target:{
                                                                        name: e.target.name,
                                                                        value: m.path
                                                                    }
                                                                },'data')}
                                                            >
                                                                Set as main
                                                            </MDButton>
                                                            <DeleteMediaButton onClick={handleDeleteMedia} id={m._id}/>
                                                        </MDBox>
                                                    </Card>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </>
                                    :
                                    <MDTypography variant={'h6'} color={'secondary'}>No Media</MDTypography>
                                }
                            </MDBox>

                            <Button disabled={articleMedia.length>3 || loading} variant={'contained'} color={'primary'} component={'label'}>
                                <MDTypography variant={'button'} color={'white'}>Upload Media</MDTypography>
                                <input type="file" hidden accept={'image/'} onChange={e=>handleUploadMedia(e)}/>
                            </Button>
                        </MDBox>
                        <CardActionArea>
                            <MDBox p={3}>
                                <MDTypography variant={'subtitle'} mt={3}>
                                    Dont forget to <span style={{color:'green'}}>Save Changes</span> after changing main image
                                </MDTypography>
                            </MDBox>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card style={{position:'sticky', top:'100px'}}>
                        <Grid container justifyContent={'space-between'} flexDirection={'column'} spacing={2} p={2}>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Article Status
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={articleData.is_active?'success':'error'}
                                    value={articleData.is_active}
                                    onChange={e=>handleChange({target:{name:'is_active', value: e.target.value === 'true'}}, 'data')}
                                    exclusive
                                >
                                    <ToggleButton value={false}>Inactive</ToggleButton>
                                    <ToggleButton value={true}>Active</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Switch Language
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'info'}
                                    value={language}
                                    exclusive
                                >
                                    <ToggleButton value={'en'}>EN</ToggleButton>
                                    <ToggleButton value={'id'}>ID</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Publish Date
                                </MDTypography>
                                <MDInput value={articleData.article_date} onChange={e=>handleChange(e,'data')} name={'article_date'} type={"date"} fullWidth/>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Category
                                </MDTypography>
                                {categories.length>0?
                                    <FormControl fullWidth sx={{height:'5vh'}}>
                                        <InputLabel>Category</InputLabel>
                                        <Select
                                            label={'Category'}
                                            sx={{height:'100%'}}
                                            value={articleData.category_id}
                                            onChange={e=>handleChange(e,'data')}
                                            name={'category_id'}
                                        >
                                            {categories.map(c => (
                                                <MenuItem value={c.id}>{c.english}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    :null
                                }
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Actions
                                </MDTypography>
                                <MDButton onClick={()=>handleSaveChanges()} disabled={loading} variant={'gradient'} color={'success'} fullWidth>
                                    <Icon>save</Icon>
                                    &nbsp;Save Changes
                                </MDButton>
                            </Grid>
                            <Grid item>
                                {resetData?
                                    <MDButton
                                        component={'a'} target={'_blank'} rel={'noreferrer'}
                                        href={URL_MATRIX_WEB + '/highlights/article/' + language + '/' + resetData.translations.find(o => o.language_code === language).slug}
                                        disabled={loading || !resetData.is_active} variant={'contained'} color={'info'} fullWidth>
                                        <Icon>open_in_new</Icon>
                                        &nbsp;Open in Website
                                    </MDButton>
                                    :null
                                }
                            </Grid>
                            <Grid item>
                                <MDButton disabled variant={'gradient'} color={'error'} fullWidth>
                                    <Icon>delete</Icon>
                                    &nbsp;Delete Article
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

        </DashboardLayout>
    )
}

export default EditArticle

const DeleteMediaButton = ({onClick, id}) => {
    const [confirm, setConfirm] = useState(false)

    const handleConfirm = () => {
        setConfirm(true)
        setTimeout(()=>{
            setConfirm(false)
        }, 3500)
    }

    return confirm?
        <MDButton color={'error'} variant={'text'} fullWidth size={'small'}
                  onClick={()=>onClick(id)}
        >
            Are you sure?
        </MDButton>
        :
        <MDButton color={'error'} variant={'text'} fullWidth size={'small'}
                  onClick={()=>handleConfirm()}
        >
            Remove
        </MDButton>
}