import React, {useEffect, useState} from 'react'
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {Alert, Grid, Icon} from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import DataTable from "../../examples/Tables/DataTable";
import MDButton from "../../components/MDButton";
import {getService} from "../../utils/RestService";
import {URL_PROD_GET_ALL} from "../../utils/url";
import MDBadge from "../../components/MDBadge";
import {Link} from "react-router-dom";

function Products(){
    //States
    const [broadbandProds, setBroadbandProds] = useState([])
    const [enterpriseProds, setEnterpriseProds] = useState([])

    //Constructors
    useEffect(() => {
        fetchProducts().then(r => null)
    },[])

    //Functions
    const fetchProducts = async() => {
        let page = 1;
        let size = 20;
        let API_URL = URL_PROD_GET_ALL(page,size)

        const result =  await getService(URL_PROD_GET_ALL(1,100))
        if(await result.resultInfo.success){
            let broadbandTemp = []
            let enterpriseTemp = []

            result.data.products.map(p => {
                if(p.is_featured){
                    //Broadband Product
                    broadbandTemp.push({
                        name: p.product_name,
                        status: (
                            <MDBox ml={-1}>
                                <MDBadge badgeContent={p.is_active? 'active':'inactive'} color={p.is_active? 'success':'secondary'} variant="gradient" size="md" />
                            </MDBox>
                        ),
                        action: (
                            <Grid container justifyContent={'space-between'} spacing={2}>
                                <Grid item>
                                    <Link to={'broadband/'+p._id}>
                                        <MDButton size={'small'} variant={'contained'} color={'secondary'}>
                                            <Icon>edit</Icon>
                                            &nbsp;Edit
                                        </MDButton>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <MDButton disabled size={'small'} variant={'text'} color={'error'}>
                                        <Icon>delete</Icon>
                                        &nbsp;Delete
                                    </MDButton>
                                </Grid>
                            </Grid>
                        )
                    })
                }else{
                    //Enterprise Product
                    enterpriseTemp.push({
                        name: p.product_name,
                        status: (
                            <MDBox ml={-1}>
                                <MDBadge badgeContent={p.is_active? 'active':'inactive'} color={p.is_active? 'success':'secondary'} variant="gradient" size="md" />
                            </MDBox>
                        ),
                        action: (
                            <Grid container justifyContent={'space-between'} spacing={2}>
                                <Grid item>
                                    <Link to={'enterprise/'+p._id}>
                                        <MDButton size={'small'} variant={'contained'} color={'secondary'}>
                                            <Icon>edit</Icon>
                                            &nbsp;Edit
                                        </MDButton>
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <MDButton disabled size={'small'} variant={'text'} color={'error'}>
                                        <Icon>delete</Icon>
                                        &nbsp;Delete
                                    </MDButton>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            })

            setBroadbandProds(broadbandTemp)
            setEnterpriseProds(enterpriseTemp)

        }
    }

    return(
        <DashboardLayout>
            <DashboardNavbar/>
            <Grid container flexDirection={'column'} justifyContent={'space-around'} spacing={5}>
                <Grid container item xs={12} mb={3} justifyContent={'flex-end'}>
                    <Grid item xs={3}>
                        <MDButton disabled color={'success'} fullWidth>
                            <Icon>add</Icon>
                            &nbsp;Create New Product
                        </MDButton>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="primary"
                            borderRadius="lg"
                            coloredShadow="primary"
                        >
                            <MDTypography variant="h6" color="white">
                                Broadband Products
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={3}>
                            <DataTable
                                isSorted={false}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                table={{
                                    columns:[
                                        {Header: 'Name', accessor:'name', width:'50%'},
                                        {Header: 'Status', accessor:'status'},
                                        {Header: 'Action', accessor:'action'}
                                    ],
                                    rows:broadbandProds
                                }}
                            />
                        </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card>
                        <MDBox
                            mx={2}
                            mt={-3}
                            py={3}
                            px={2}
                            variant="gradient"
                            bgColor="warning"
                            borderRadius="lg"
                            coloredShadow="warning"
                        >
                            <MDTypography variant="h6" color="white">
                                Enterprise Products
                            </MDTypography>
                        </MDBox>
                        <MDBox pt={3}>
                            <DataTable
                                isSorted={false}
                                entriesPerPage={false}
                                showTotalEntries={false}
                                table={{
                                    columns:[
                                        {Header: 'Name', accessor:'name', width:'50%'},
                                        {Header: 'Status', accessor:'status'},
                                        {Header: 'Action', accessor:'action'}
                                    ],
                                    rows:enterpriseProds
                                }}
                            />
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    )
}

export default Products