import React, {useEffect, useState} from 'react'
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import {useParams} from "react-router-dom";
import {getService, postService} from "../../utils/RestService";
import {URL_CAR_GET_ID, URL_CAR_UPDATE, URL_MATRIX_WEB} from "../../utils/url";
import MDAlert from "../../components/MDAlert";
import {
    Divider,
    FormControl,
    Grid,
    Icon, IconButton, InputAdornment,
    InputLabel,
    MenuItem,
    Select, TextField,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import MDInput from "../../components/MDInput";

function EditJobPost(){
    //States
    const careerId = useParams().id
    const language = 'en';
    const [resetData, setResetData] = useState(null)
    const [jobTitle, setJobTitle] = useState('')
    const [jobStatus, setJobStatus] = useState(true)
    const [jobDescription, setJobDescription] = useState([])
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState({color:'warning', message:''})

    //Constructors
    useEffect(()=>{
        fetchCareer().then(r => null)
    },[])

    //Functions
    const fetchCareer = async() => {
        const result = await getService(URL_CAR_GET_ID(careerId))
        if(await result.resultInfo.success){
            let data = result.data.job_post;
            setResetData(data)
            setJobStatus(data.is_active)
            setJobTitle(data.translations.find(o=>o.language_code===language).job_title)
            setJobDescription(data.translations.find(o=>o.language_code===language).content.description)
        }
    }

    const handleResetChanges = () => {
        setJobStatus(resetData.is_active)
        setJobTitle(resetData.translations.find(o=>o.language_code===language).job_title)
        setJobDescription(resetData.translations.find(o=>o.language_code===language).content.description)
    }

    const handleChangeDescription = (event, index) => {
        let temp = jobDescription.slice()
        temp[index] = event.target.value
        setJobDescription(temp)
    }

    const handleAddDescription = () => {
        let temp = jobDescription.slice();
        temp.push('New Description')
        setJobDescription(temp)
    }
    const handleRemoveDescription = (index) => {
        let temp = jobDescription.slice();
        temp.splice(index, 1)
        setJobDescription(temp)
    }

    const handleSaveChanges = async() => {
        setLoading(true)
        setAlert({color:'info', message:'Updating Page. Please Wait'})

        let body = {
            _id: careerId,
            is_active: jobStatus,
            translations: [{
                language_code: language,
                job_title: jobTitle,
                content:{
                    description: jobDescription
                }
            }]
        }

        const result = await postService(URL_CAR_UPDATE, body)
        if(await result.resultInfo.success){
            setLoading(false)
            setAlert({color:'success', message:'Successfully updated page'})

            let data = result.data.job_post;
            setResetData(data)
        }

        setTimeout(() => {
            setAlert({color:'success', message:''})
        }, 3000);

    }

    return(
        <DashboardLayout>
            <DashboardNavbar/>

            {alert.message?
                <MDAlert color={alert.color}>{alert.message}</MDAlert>
                :null
            }

            <Grid container spacing={2}>
                <Grid item xs={9}>
                    <Card>
                        <MDBox p={3}>
                            <Grid container justifyContent={'space-between'}>
                                <Grid item>
                                    <MDTypography variant="h6" gutterBottom>
                                        Job Title
                                    </MDTypography>
                                </Grid>
                                <Grid item>
                                    <MDButton onClick={()=>handleResetChanges()} disabled={loading} color={'dark'}>
                                        <Icon>restart_alt</Icon>
                                        &nbsp;Reset All Changes
                                    </MDButton>
                                </Grid>
                            </Grid>

                            <MDInput value={jobTitle} onChange={e=>setJobTitle(e.target.value)} label={'Title'} fullWidth margin={'normal'} multiline/>
                        </MDBox>
                        <MDBox p={3}>
                            <MDTypography variant="h6" gutterBottom>
                                Job Description
                            </MDTypography>

                            {jobDescription.length>0?
                                jobDescription.map((j, index) => (
                                    <MDInput
                                        key={index} value={j} onChange={e=>handleChangeDescription(e, index)}
                                        InputProps={{
                                            endAdornment:
                                                <InputAdornment position={'end'}>
                                                    <MDButton
                                                        onClick={()=>handleRemoveDescription(index)}
                                                        circular color={'error'} variant={'text'} iconOnly>
                                                        <Icon>clear</Icon>
                                                    </MDButton>
                                                </InputAdornment>
                                        }}
                                        label={'Description'} fullWidth margin={'dense'} size={'small'}/>
                                ))
                                :
                                <MDTypography variant="subtitle2" gutterBottom>
                                    No Description Yet
                                </MDTypography>
                            }

                            <MDBox mt={2}>
                                <MDButton onClick={()=>handleAddDescription()} color={'light'}>
                                    <Icon>add</Icon>
                                    &nbsp;Add Description
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </Card>
                </Grid>
                <Grid item xs={3}>
                    <Card>
                        <Grid container justifyContent={'space-between'} flexDirection={'column'} spacing={2} p={2}>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Job Post Status
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'success'}
                                    value={jobStatus}
                                    exclusive
                                >
                                    <ToggleButton value={false}>Inactive</ToggleButton>
                                    <ToggleButton value={true}>Active</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Switch Language
                                </MDTypography>
                                <ToggleButtonGroup
                                    color={'info'}
                                    value={language}
                                    exclusive
                                >
                                    <ToggleButton value={'en'}>EN</ToggleButton>
                                    <ToggleButton value={'id'}>ID</ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            <Grid item>
                                <MDTypography variant="h6" gutterBottom>
                                    Actions
                                </MDTypography>
                                <MDButton onClick={()=>handleSaveChanges()} disabled={loading} variant={'gradient'} color={'success'} fullWidth>
                                    <Icon>save</Icon>
                                    &nbsp;Save Changes
                                </MDButton>
                            </Grid>
                            <Grid item>
                                <MDButton disabled variant={'gradient'} color={'error'} fullWidth>
                                    <Icon>delete</Icon>
                                    &nbsp;Delete Job Post
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>

        </DashboardLayout>
    )
}

export default EditJobPost